<template>
  <div >
    <el-row>
      <el-button style="float: left" size="mini" type="primary" icon="el-icon-plus" @click="handleConnectorAdd">{{$t('Add')+$t('ChargingConnector')}}</el-button>
      <div style="float: right;">
        <el-select v-model="listQuery.serviceId" clearable size="small" v-bind:placeholder="$t('AllOperator')" class="filter-item-mid" v-if="serviceList.length>0">
          <el-option v-for="item in serviceList" :key="item.id" :label="item.name + ' (' + item.code + ')'" :value="item.id" />
        </el-select>
        <el-select v-model="listQuery.stationId" :placeholder="$t('All')+' '+$t('ChargingStation')" size="small" class="filter-item" clearable>
          <el-option
                v-for="item in this.chargingStations"
                :label="item.name"
                :key="item.id"
                :value="item.id">
          </el-option>
        </el-select>
        <el-select v-model="listQuery.enabledOcpi" size="small" class="filter-item">
          <el-option v-bind:label="'OCPI'+$t('Enable')" :value="true" />
          <el-option v-bind:label="'OCPI'+$t('NotEnabled')" :value="false" />
        </el-select>
      </div>
    </el-row>

    <el-table
      :header-cell-style="{color:'#20638C'}"
      v-loading="listLoading"
      :data="list" style="width: 100%;"
      :cell-style="{padding: '0', height: '40px'}"
      @sort-change="tableSortChange"
     >
      <el-table-column align="center" prop="connectorId" v-bind:label="$t('Number')" min-width="30%"/>
      <el-table-column align="center" prop="station.service.name" :label="$t('Operator')" v-if="serviceList.length>0" min-width="80%" />
      <el-table-column align="center" prop="station.name" v-bind:label="$t('ChargingStation')" sortable/>
      <el-table-column align="center" prop="chargePointId" v-bind:label="$t('ChargingPoint')" :formatter="showPointName" sortable />
      <el-table-column align="center" prop="acDc" v-bind:label="$t('PowerSupply')" min-width="60%"/>
      <el-table-column align="center" prop="connectorType" v-bind:label="$t('PlugType')" min-width="60%"/>
      <el-table-column align="center" prop="hasLockFeature" label="Lock" min-width="60%">
          <template slot-scope="scope">
              <el-tag v-if="scope.row.hasLockFeature" type="danger"> {{$t('Yes')}} </el-tag>
              <el-tag v-else type="success"> {{$t('No')}} </el-tag>
          </template>
      </el-table-column>
      <el-table-column  align="center" prop="createTime" v-bind:label="$t('Create')+$t('Date')" :show-overflow-tooltip="true" min-width="60%">
          <template slot-scope="scope">
             {{ parseDateTime(scope.row.createTime) }}
          </template>
      </el-table-column>
      <el-table-column align="center" label="QRcode" min-width="60%">
          <template slot-scope="scope">
             <el-button type="success" size="mini" icon="el-icon-picture-outline" @click="showConnectorQRcode(scope.row)"/>
          </template>
      </el-table-column>
      <el-table-column align="center" :label="$t('Operate')" width="130px" >
          <template slot-scope="scope">
             <el-button type="success" :title="$t('Edit')" size="mini" icon="el-icon-setting" @click="showConnectorSetting(scope.row)"/>
             <el-button type="danger" :title="$t('Delete')" size="mini" icon="el-icon-delete" v-if="!scope.row.station.enabledOCPI" @click="handleConnectorDelete(scope.$index, scope.row)" />
          </template>
      </el-table-column>
    </el-table>
    <!--分頁組件-->
    <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />
    <ConnectorSetting :dialogConnectorVisible="dialogConnectorSettingVisible" :connectorData="connectorData" :serviceList="serviceList" :packageList="packageList" @close="dialogConnectorSettingOnClose()" />  <!-- bowa modify 0802-->
    <ConnectorQRcode :dialogQRcodeVisible="dialogConnectorQRcodeVisible" :qrcodeData="qrcodeData" @close="dialogConnectorQRcodeOnClose()" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { mixins } from '@/views/common/mixins.js'
import ConnectorSetting from './ConnectorSetting.vue'
import ConnectorQRcode from './ConnectorQRcode.vue'
import {refreshToken,stopRefreshToken} from "@/utils/auth";
import {apiUrl} from "@/utils/main";

export default {
  name: 'Connector',
  components: {
    Pagination,
    ConnectorSetting,
    ConnectorQRcode
  },
  mixins: [mixins],
  data() {
    return {
      result: '',
      dialogWidth: 0,
      total: 0,
      list: [],
      serviceList: [],
      packageList:[], //bowa add 0802
      chargingStations: this.$store.state.chargingStations,
      listLoading: false,
      listQuery: {
        serviceId: undefined,
        stationId: undefined,
        enabledOcpi: false,
        page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        size: 15,
        sort: 'id,Desc'
      },
      paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
      connectorData: undefined, // 充電槍資訊
      qrcodeData: undefined, // 充電槍資訊
      dialogConnectorSettingVisible: false,
      dialogConnectorQRcodeVisible: false,
    };
  },
  watch: {
    paginationSelectedPage: {
      handler: function(newValue) {
        this.listQuery.page = newValue - 1
      }
    },
    'listQuery.serviceId': function () {
        this.listQuery.page = 0
        this.resetPage(this.getList)
        this.chargingStations = (this.$store.state.chargingStations || []).filter(item => item.service_id == this.listQuery.serviceId)
        /* 因為route傳過來的serviceId等同讓listQuery.serviceId發生變化,為了避免listQuery.stationId在這被洗成undefined 因此加上判斷 */
        if(this.$route.params.stationId != undefined) { 
          this.listQuery.stationId = Number(this.$route.params.stationId) //若沒有轉成數值,那el-select選單無法以key導入使之正確呈現名稱
          this.$route.params.stationId = undefined 
        } else  
          this.listQuery.stationId = undefined
        /*--------------------------------------------------------------------------------------------------------------------*/
    },
    'listQuery.stationId': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    },
    'listQuery.enabledOcpi': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    },
  },
  created() {

    /* Dashboard.vue route過來的參數 by企分會議需求 */
    if(this.$route.params.serviceId != undefined) this.listQuery.serviceId = Number(this.$route.params.serviceId)
    if(this.$route.params.stationId != undefined) this.listQuery.stationId = Number(this.$route.params.stationId)    
    /* Dashboard.vue route過來的參數 by企分會議需求 */
    
    this.dialogWidth = this.setDialogWidth()
    this.resetPage(this.getList)
    this.getServiceOptions();
    //refreshToken();
  
  },
  beforeDestroy() {
    //stopRefreshToken();
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.dialogWidth = this.setDialogWidth()
      })()
    }
  },
  methods: {
    showPointName(row,column,cellValue) {
      let item = (this.$store.state.chargingPoints || []).find((item) => item.id === cellValue)
      return item != undefined? item.name: cellValue;

      //return this.$store.state.chargingPoints.filter(function(item, index, array){
          //return item.id == cellValue;
      //})[0].name;
    },
    getList() {
      this.listLoading = true
      this.axios.get('/api/v1/connector', {params: this.listQuery}).then(res=> {
        this.list = res.data.content
        this.total = res.data.totalElements
        this.listLoading = false
      })
    },
    getServiceOptions() {
        this.listLoading = true
        this.axios.get('/api/v1/common/service/enabled').then(res => {
            this.serviceList = res.data
            if (this.serviceList.length==0) {
                this.listQuery.serviceId = this.$store.state.currentAccount.serviceInfo.id //this.list[0].station.service.id
                this.chargingStations = (this.$store.state.chargingStations || []).filter(item => item.service_id == this.listQuery.serviceId)
            }
        })
    },
    getPackageList(val){   // bowa add
     // console.log(" getPackageList  -- connector")
            //this.strURL ="/api/v1/packages/query/name_list?serviceId="+this.listQuery.serviceId
        this.strURL ="/api/v1/packages/query/name_list?serviceId="+val
        this.axios.get(this.strURL).then(
        res => {
        if(res.data.message=='ok')
        {
          this.packageList=res.data.data;   
        }  
        else
        {
          this.$message({ message:'查詢失敗', type: 'warning' })
        }
        }
       );
    },
    handleConnectorAdd() {
    this.dialogConnectorSettingVisible = true
     if( this.listQuery.serviceId != undefined) //bowa add 0805
     {
      //console.log('handleConnectorAdd');
     // console.log(this.listQuery.serviceId);
      this.getPackageList(this.listQuery.serviceId); //bowa add 0805
     }
    },
    showConnectorSetting(row) {
      this.dialogConnectorSettingVisible = true
      this.connectorData = row
      //if(row.station.service.id != null)
      this.getPackageList(row.station.service.id); //bowa add 0805
    },
    showConnectorQRcode(row) {
      this.dialogConnectorQRcodeVisible = true
      this.qrcodeData = row
    },
    handleConnectorDelete(index, row) {
      let item = (this.$store.state.chargingPoints || []).find((item) => item.id === row.chargePointId)
      if (item != undefined) item = item.name
      else item = row.chargePointId
      this.$confirm('提示', {
        message: '確定刪除充電槍 - ' + row.station.name + ' - '+ item + ' - ' + row.connectorId +' ?',
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delConnector(index, row.chargePointId, row.connectorId)
      }).catch(()=> {
        console.log('cancel delete point')
      })
    },
    async delConnector(index, chargePointId, connectorId) {
      this.axios.delete('/api/v1/connector/' + chargePointId + '/' + connectorId).then(()=> {
        this.$store.dispatch("getConnectorByCurrentAccount") // 更新所有充電槍資訊
        this.$delete(this.list, index);
        this.$message({showClose: true,message: '刪除成功',type: 'success'})
      }).catch(err => {
        this.$message({message: '刪除失敗',type: 'error'})
      })
    },
    dialogConnectorSettingOnClose() {
      this.dialogConnectorSettingVisible = false
      this.connectorData = undefined
      this.resetPage(this.getList)
    },
    dialogConnectorQRcodeOnClose() {
      this.dialogConnectorQRcodeVisible = false
      this.connectorData = undefined
      this.resetPage(this.getList)
    },
  }
}
</script>
