<template>
    <el-dialog width="600" title="QR code" :visible="dialogQRcodeVisible" @close="dialogConnectorQRcodeOnClose()">
        <el-row>
            <span style="font-size:18px">{{ updateQuery.stationName+ ' - '+ updateQuery.chargePointName + ' - ' + updateQuery.connectorId }}</span>
        </el-row>
        <el-row>
            <canvas id="canvas"/>
        </el-row>
        <el-button size="median" type="primary" @click="downloadQRcode()">下載</el-button>
    </el-dialog>
</template>

<script>
import { mixins } from '@/views/common/mixins.js'
import QRCode from 'qrcode'

export default {
    name: 'ConnectorQRcode',
    mixins: [mixins],
    props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
        qrcodeData: {
            require: false,
            default: undefined,
            type: Object
        },
        dialogQRcodeVisible: {
            require: true,
            default: undefined,
            type: Boolean
        }
    },
    data() {
        return {
            updateQuery: {
                connectorId: undefined,
                stationId: undefined,
                stationName: undefined,
                chargePointName: undefined,
                chargePointId: undefined,
            },
        };
    },
    watch: {
        qrcodeData(val) {
            if (val) {
                this.updateQuery.connectorId = val.connectorId
                this.updateQuery.stationId = val.station.id
                this.updateQuery.stationName = val.station.name
                this.updateQuery.chargePointName = this.$store.state.chargingPoints.find((item) => item.id === val.chargePointId).name
                this.updateQuery.chargePointId = val.chargePointId
            }
        }
    },
    updated() {
         const canvas = document.getElementById("canvas");
         QRCode.toCanvas(canvas,'sid='+this.updateQuery.stationId+'&cp='+this.updateQuery.chargePointId+'&id='+this.updateQuery.connectorId,{width:250,margin:5});
    },
    methods: {
        dialogConnectorQRcodeOnClose() {
            this.$emit('close') // 觸發父層 close
        },
        downloadQRcode() {
            var link = document.createElement('a');
            link.download = 'qrcode.png';
            link.href = document.getElementById('canvas').toDataURL();
            link.click();
        },
    }
}
</script>