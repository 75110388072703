<template>
    <el-dialog width="600" v-bind:title="$t('CS.connector')" :visible="dialogConnectorVisible" @close="dialogConnectorOnClose()">
        <el-form ref="dataForm" :rules="rules" :model="updateQuery" size="small">
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11" v-if="serviceList.length>0">
                    <el-form-item prop="serviceId" v-bind:label="$t('Operator')">
                        <el-select id="serviceId" v-model="serviceId" :disabled="this.isModify" size="small" class="filter-item">
                            <el-option
                                  v-for="item in this.filterServiceList"
                                  :label="item.name"
                                  :key="item.id"
                                  :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="stationId" v-bind:label="$t('ChargingStation')">
                        <el-select id="stationId" v-model="updateQuery.stationId" :disabled="this.isModify" size="small" class="filter-item">
                            <el-option
                                  v-for="item in this.chargingStations"
                                  :label="item.name"
                                  :key="item.id"
                                  :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="chargePointId" v-bind:label="$t('ChargingPointS')">
                        <el-select id="chargePointId" v-model="updateQuery.chargePointId" :disabled="this.isModify" size="small" class="filter-item">
                            <el-option
                                  v-for="item in this.chargingPoints"
                                  :label="item.name"
                                  :key="item.id"
                                  :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="connectorId" v-bind:label="$t('Number')">
                        <el-select id="connectorId" v-model="updateQuery.connectorId" :disabled="this.isModify" size="small" class="filter-item">
                            <el-option label="1" :value="1" />
                            <el-option label="2" :value="2" />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="acDc" v-bind:label="$t('PowerSupply')">
                        <el-select id="acDc" v-model="updateQuery.acDc" size="small" class="filter-item">
                            <el-option label="AC" value="AC" />
                            <el-option label="DC" value="DC" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="connectorType" v-bind:label="$t('PlugType')">
                        <el-select id="connectorType" v-model="updateQuery.connectorType" size="small" class="filter-item">
                            <el-option
                                  v-for="item in this.connectorTypeList"
                                  :label="item.value"
                                  :key="item.value"
                                  :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="hasLockFeature" label="Lock">
                        <el-select id="hasLockFeature" v-model="updateQuery.hasLockFeature" size="small" class="filter-item">
                            <el-option v-bind:label="$t('Yes')" :value="true" />
                            <el-option v-bind:label="$t('No')" :value="false" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="rate" v-bind:label="$t('Rate')" v-show="false">
                        <el-input id="rate" size="small" v-model="updateQuery.rate" :placeholder="$t('Input')+' '+$t('Rate')" class="filter-item" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">  <!--bowa add 0802-->
                    <el-form-item prop="charging_packages_id" v-bind:label="$t('Package')" clearable>
                        <el-tooltip class="item" effect="light" content="如果費率包選項為空,請到費率包新增" placement="top">
                        <el-select id="packageRate" v-model="packageRate" size="small" class="filter-item" clearable >
                            <el-option v-for="item in this.packageList" :key="item.id" :label="item.pkname" :value="item.id"/>
                        </el-select>
                              
                    </el-tooltip>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-button style="margin-bottom: 0px; margin-top: 10px;" size="median" type="primary" v-if="!this.enabledOCPI" @click="updateConnector()">{{$t('Save')}}</el-button>
    </el-dialog>
</template>

<script>
import { mixins } from '@/views/common/mixins.js'
import {apiUrl} from "@/utils/main";

export default {
    name: 'ConnectorSetting',
    mixins: [mixins],
    props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
        connectorData: {
            require: false,
            default: undefined,
            type: Object
        },
        serviceList: {
            require: false,
            default: undefined,
            type: Array
        },
        dialogConnectorVisible: {
            require: true,
            default: undefined,
            type: Boolean
        },
        packageList: {
            require: false,
            default: undefined,
            type: Object
        },
    },
    data() {
        let rateValid = (rule, value, callback) =>{
            if(/^[0-9]+\.?[0-9]*$/.test(value)){
                callback();
            }else{
                callback(new Error("費率格式不正確"));
            }
        };
        return {
            serviceId: undefined,
            chargingStations: this.$store.state.chargingStations,
            chargingPoints: undefined,
            AClist: [{value:'Type1 J1772'},{value:'Type2'}],
            DClist: [{value:'CCS1'},{value:'CCS2'}],
            connectorTypeList: [{value:'Type1 J1772'},{value:'Type2'}],
            packageRate:this.packageList,  //bowa add 0802
            isModify: false,
            updateQuery: {
                connectorId: "1",
                stationId: undefined,
                chargePointId: undefined,
                acDc: "AC",
                connectorType: "Type1 J1772",
                hasLockFeature: true,
                rate: 0, //bowa 0805修改
                chargingPackageId:undefined //bowa 0802 加入
            },
            enabledOCPI: false,
            filterServiceList: [],
            rules: {
                connectorId: [{ required: true, message: '(必填)', trigger: 'blur' }],
                stationId: [{ required: true, message: '(必填)', trigger: 'blur' }],
                chargePointId: [{ required: true, message: '(必填)', trigger: 'blur' }],
                acDc: [{ required: true, message: '(必填)', trigger: 'blur' }],
                connectorType: [{ required: true, message: '(必填)', trigger: 'blur' }],
                hasLockFeature: [{ required: true, message: '(必填)', trigger: 'blur' }],
                //rate: [{ required: true, validator: rateValid, trigger: 'blur' }],  //因應資分反映, 拿掉必填的驗證
               // charging_packages_id: [{ required: true, message: '(必填)', trigger: 'blur' }]
                //packageRate: [{ required: true, message: '(必填)', trigger: 'blur' }]
               
            },
        
        };
    },
   
    watch: {
        connectorData(val) {
            if (val) {
                this.isModify = true
                this.serviceId = val.station.service.id
                this.updateQuery.connectorId = val.connectorId
                this.updateQuery.stationId = val.station.id
                this.updateQuery.chargePointId = val.chargePointId
                this.updateQuery.acDc = val.acDc
                this.updateQuery.connectorType = val.connectorType
                this.updateQuery.hasLockFeature = val.hasLockFeature
                this.updateQuery.rate = val.rate
                this.enabledOCPI = val.station.service.enabledOCPI
                this.updateQuery.chargingPackageId = val.chargingPackage.id //bowa 0802 加入
            }
        
        },
        serviceList(val) {
            if (val)
                this.filterServiceList = this.serviceList.filter(item => item.enabledOCPI==false)
        },
        'serviceId': function () {
            this.chargingStations = (this.$store.state.chargingStations || []).filter(item => item.service_id == this.serviceId)
            if (!this.isModify ) 
            {
                this.updateQuery.stationId = undefined
               // console.log("this.serviceId:"+ this.serviceId)
                this.getPackageList()
            }
           this.packageRate=this.updateQuery.chargingPackageId
        },
      
        'updateQuery.stationId':{
             handler(newValue, oldValue){
                this.chargingPoints = (this.$store.state.chargingPoints || []).filter(function(item, index, array){
                    return item.station_id == newValue;
                });
                if (!this.isModify) this.updateQuery.chargePointId = undefined;
             },
        },
        'updateQuery.acDc':{
              handler(newValue, oldValue){
                 if (newValue == 'AC') this.connectorTypeList = this.AClist;
                 else this.connectorTypeList = this.DClist;
                 if (!this.isModify) this.updateQuery.connectorType = this.connectorTypeList[0].value;
              },
        },
        'packageRate':{
              handler(newValue, oldValue){
                 this.updateQuery.chargingPackageId=newValue
                //console.log(" this.updateQuery.chargingPackageId:"+ this.updateQuery.chargingPackageId)
              },
        },

    },
    methods: {
        dialogConnectorOnClose() {
            this.resetUpdateQuery()
            this.$refs.dataForm.resetFields() // 表單校驗結果重置
            this.$emit('close') // 觸發父層 close
        },
        updateConnector() {

          if(this.packageRate==undefined)
          {
            return this.$message({ message: '請選擇費率包', type: 'warning' })
          }
            this.$refs['dataForm'].validate((valid) => {
                if (valid) {
                    this.axios.put('/api/v1/connector', this.updateQuery).then(() => {
                        this.$store.dispatch("getConnectorByCurrentAccount") // 更新所有充電槍資訊
                        this.$message({
                            showClose: true,
                            message: '修改成功',
                            type: 'success'
                        })
                        this.dialogConnectorOnClose()
                    }).catch(err => {
                        if(err.data=='Need chargingPackageId!')
                        {
                            this.$message({
                            message: '修改失敗:請選擇費率包',
                            type: 'error'
                        })
                        }
                        else{
                            this.$message({
                            message: '修改失敗 '+err.data,
                            type: 'error'
                        })
                        }
                    })
                } else {
                    this.$message({
                        message: '輸入欄位格式不正確',
                        type: 'error'
                    })
                }
            })
        },
        resetUpdateQuery() {
            this.isModify = false
            this.serviceId = undefined,  
            this.updateQuery = {
                connectorId: "1",
                stationId: undefined,
                chargePointId: undefined,
                acDc: "AC",
                connectorType: "AC Type1 J1772",
                hasLockFeature: true,
                rate: undefined,
               // packageRate:undefined, //bowa add 0823
                //charging_packages_id:undefined,//bowa add 0823
            }
            this.enabledOCPI = false
        },
       
       getPackageList(){   // bowa add
            this.packageList=[]
            this.charging_packages_id=''
            this.packageRate=''
           if(this.serviceId != undefined && this.isModify==false )
           {
           this.strURL ="/api/v1/packages/query/name_list?serviceId="+this.serviceId
           this.axios.get(this.strURL).then(
           res => {
        if(res.data.message=='ok')
        {
          this.packageList=res.data.data;   
        }  
        else
        {
          this.$message({ message:'查詢失敗', type: 'warning' })
        }
        }
       );
       }
            
  },

    }
}
</script>
<style>
/* Medium devices (tablets, 768px and up) */
@media (max-width: 767.98px) {
    .el-form-item__content {
        text-align: left;
    }
}
@media (min-width: 768px) {
    .el-input {
        width: 200px;
    }
    .el-select {
        width: 200px;
    }
    .el-form-item__content {
        text-align: left;
        margin-left: 110px;
    }
    .el-form-item__label {
        width: 110px;
    }
 }
</style>